import { useEffect, useState } from "react";
import { getRating } from "../../../services/api.services";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const RatingAverage = () => {
  const { id } = useParams();
  const [rating, setRating] = useState([]);

  // Mengambil data rating dari API
  useEffect(() => {
    const fetchRating = async () => {
      try {
        const response = await getRating(id);
        if (Array.isArray(response)) {
          setRating(response);
        } else {
          console.error("Response is not an array");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchRating();
  }, [id]);

  // Menghitung rata-rata rating
  const calculateAverageRating = (data) => {
    if (data.length === 0) return 0;
    const totalRating = data.reduce(
      (acc, item) => acc + parseFloat(item.rating),
      0
    );
    const numberOfRatings = data.length;
    return totalRating / numberOfRatings;
  };

  const averageRating = calculateAverageRating(rating);

  // Komponen untuk menampilkan bintang
  const Star = ({ filled }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={filled ? "#b83bb3" : "#e0e0e0"}
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>
  );

  // Membuat array dari bintang yang terisi
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(<Star key={i} filled={i <= averageRating} />);
  }

  return (
    <div className="d-flex align-items-center justify-items-center gap-2">
      <h4 className="fw-bold">{averageRating.toFixed(2)}</h4>
      <div className="d-flex">{stars}</div>
      <p className="fs-6">({rating.length})</p>
    </div>
  );
};

export const RatingFilter = ({ selectedRating, onFilterChange }) => {
  const { id } = useParams();
  const [rating, setRating] = useState([]);

  const handleFilterChange = (rating) => {
    onFilterChange(rating);
  };
  useEffect(() => {
    const fetchRating = async () => {
      try {
        const response = await getRating(id);
        if (Array.isArray(response)) {
          setRating(response);
        } else {
          console.error("Response is not an array");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchRating();
  }, [id]);

  return (
    <div className="d-flex flex-wrap gap-3">
      <button
        className="rounded-3 p-2 d-flex align-items-center"
        style={{
          border: "2px solid #b83bb3",
          backgroundColor: selectedRating === null ? "#b83bb3" : "white",
          color: selectedRating === null ? "white" : "#b83bb3",
        }}
        onClick={() => handleFilterChange(null)}
      >
        Semua Rating ({rating.length})
      </button>
      {[...Array(5).keys()].map((i) => (
        <button
          key={i + 1}
          className="rounded-3 p-2 d-flex align-items-center"
          style={{
            border: "2px solid #b83bb3",
            backgroundColor: selectedRating === 5 - i ? "#b83bb3" : "white",
            color: selectedRating === 5 - i ? "white" : "#b83bb3",
          }}
          onClick={() => handleFilterChange(5 - i)}
        >
          {5 - i} Bintang (
          {rating.filter((item) => parseInt(item.rating) === 5 - i).length})
        </button>
      ))}
    </div>
  );
};

export const RatingList = ({ ratingData }) => {
  return (
    <div className="mt-10 d-flex flex-column gap-3">
      {ratingData.length === 0 ? (
        <div className="text-center p-5">
          <h4>Tidak ada ulasan...</h4>
        </div>
      ) : (
        ratingData.map((item) => (
          <div className="border p-3 rounded-3" key={item.id}>
            <div className="d-flex flex-column gap-1">
              <h6>{item.name}</h6>
              <div className="d-flex gap-1">
                {[...Array(parseInt(item.rating))].map((_, index) => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#b83bb3"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                    key={index}
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                ))}
              </div>
              <h7 className="text-dark">Varian : {item.item_name}</h7>
              <h6 className="text-dark">{item.review}</h6>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

// export const RatingFilter = ({
//   selectedRating,
//   onFilterChange,
//   getRatingCount,
// }) => {
//   const { id } = useParams();
//   const [rating, setRating] = useState([]);

//   // Mengambil data rating dari API
//   useEffect(() => {
//     const fetchRating = async () => {
//       try {
//         const response = await getRating(id, selectedRating);
//         if (Array.isArray(response)) {
//           setRating(response);
//         } else {
//           console.error("Response is not an array");
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchRating();
//   }, [id, selectedRating]);

//   const handleFilterChange = (rating) => {
//     onFilterChange(rating);
//   };

//   return (
//     <div className="d-flex flex-wrap gap-3">
//       <button
//         className="rounded-3 p-2 d-flex align-items-center"
//         style={{
//           border: "2px solid #b83bb3",
//           backgroundColor: selectedRating === null ? "#b83bb3" : "white",
//           color: selectedRating === null ? "white" : "#b83bb3",
//         }}
//         onClick={() => handleFilterChange(null)}
//       >
//         {selectedRating === null && (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="16"
//             height="16"
//             fill="white"
//             className="bi bi-check-lg"
//             viewBox="0 0 16 16"
//           >
//             <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
//           </svg>
//         )}
//         Semua Rating ({rating.length})
//       </button>
//       {[...Array(5).keys()].map((i) => (
//         <button
//           key={i + 1}
//           className="rounded-3 p-2 d-flex align-items-center"
//           style={{
//             border: "2px solid #b83bb3",
//             backgroundColor: selectedRating === 5 - i ? "#b83bb3" : "white",
//             color: selectedRating === 5 - i ? "white" : "#b83bb3",
//           }}
//           onClick={() => handleFilterChange(5 - i)}
//         >
//           {selectedRating === 5 - i && (
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="16"
//               height="16"
//               fill="white"
//               className="bi bi-check-lg"
//               viewBox="0 0 16 16"
//             >
//               <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
//             </svg>
//           )}
//           {5 - i} Bintang ({getRatingCount(5 - i)})
//         </button>
//       ))}
//     </div>
//   );
// };

// export const RatingList = ({ ratingData }) => {
//   const { id } = useParams();
//   const [rating, setRating] = useState([]);

//   // Mengambil data rating dari API
//   useEffect(() => {
//     const fetchRating = async () => {
//       try {
//         const response = await getRating(id);
//         if (Array.isArray(response)) {
//           setRating(response);
//         } else {
//           console.error("Response is not an array");
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchRating();
//   }, [id, ratingData]);

//   return (
//     <div className="mt-10 d-flex flex-column gap-3">
//       {rating.length === 0 ? (
//         <div className="text-center p-5">
//           <h3>Tidak tersedia...</h3>
//         </div>
//       ) : (
//         rating.map((item) => (
//           <div className="border p-3 rounded-3" key={item.id}>
//             <div className="d-flex flex-column gap-1">
//               <h6>{item.name}</h6>
//               {/* <h7 className="text-dark">{item.date}</h7> */}
//               <div className="d-flex gap-1">
//                 {[...Array(parseInt(item.rating))].map((_, index) => (
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="20"
//                     height="20"
//                     fill="#b83bb3"
//                     className="bi bi-star-fill"
//                     viewBox="0 0 16 16"
//                     key={index}
//                   >
//                     <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
//                   </svg>
//                 ))}
//               </div>
//               <h7 className="text-dark">Varian : {item.item_name}</h7>
//               <h6 className="text-dark">{item.review}</h6>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };
