import React, { useEffect, useState } from "react";
import { postData } from "../../services/api.services";
import { userInfo } from "./AuthProvider";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

function Login() {
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
  
    const [authLogin, setAuthLogin] = useState({
      username: '',
      password: ''
    });
  
    const handleInput = (event) => {
        const { name, value } = event.target;
        setAuthLogin({
            ...authLogin,
            [name]: value
        });
    };
    const handlePost = async (event) => {
        event.preventDefault();
        const response = await postData('auth/login', authLogin);
        if (response && response.data.success == true) {
            const { data } = response;
            const expiresInMilliseconds = data.expires_in * 1000;
            const expiresAt = Date.now() + expiresInMilliseconds;
            const readableExpiresAt = new Date(expiresAt).toLocaleString();
  
            localStorage.setItem('user-info', JSON.stringify({ 
                ...data, 
                expires_in: expiresAt, 
                readable_expires_in: readableExpiresAt 
            }));
            window.location.href = '/';
        } else {
          setAlertMessage('Login failed, verify your username and password');
          setAlertVisible(true);
          setTimeout(() => {
              setAlertVisible(false);
          }, 3000);
        }
    };
  
    if (!userInfo) {
      return (
        <div id="login">
            <main class="main pages">
                <div class="page-header breadcrumb-wrap">
                    <div class="container">
                        <div class="breadcrumb">
                            <a href="/" rel="nofollow"><i class="fi-rs-home mr-5"></i>Beranda</a>
                            <span></span> Masuk
                        </div>
                    </div>
                </div>
                <div class="page-content pt-150 pb-150">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-8 col-lg-10 col-md-12 m-auto">
                                <div class="row">
                                    <div class="col-lg-6 col-md-8 m-auto text-center">
                                        <div class="login_wrap widget-taber-content background-white">
                                            <div class="padding_eight_all bg-white">
                                                <div class="heading_s1">
                                                    <h2 class="mb-5">Silahkan Masuk</h2>
                                                    {alertVisible && (
                                                        <div className="relative top-5 w-75 bg-danger text-white py-1 rounded shadow-md m-auto text-sm">
                                                            {alertMessage}
                                                        </div>
                                                    )}
                                                    <p className="mb-30"></p>
                                                </div>

                                                <form onSubmit={handlePost}>
                                                    <div class="form-group">
                                                        <input type="text" required="" onChange={handleInput} value={authLogin.username} id="username" name="username" placeholder="Username *" />
                                                    </div>
                                                    <div class="form-group">
                                                        <input required="" type="password" onChange={handleInput} value={authLogin.password} id="password" name="password" placeholder="Your password *" />
                                                    </div>
                                                    <div className="mb-30"></div>
                                                    <div class="login_footer form-group mb-50">
                                                        <p>Belum punya akun? <a href="/register">Daftar</a></p>
                                                        {/* <a class="text-muted" href="#">Forgot password?</a> */}
                                                    </div>
                                                    <div class="form-group" onClick={handlePost}>
                                                        <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Masuk</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      );
    }else{
      return <Redirect to="/" />;
    }
}

export default Login;