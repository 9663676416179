import { useEffect, useState } from "react";
import { getProductsEthica } from "../../services/api.services";
import { formatPrice } from "../../services/function";

export const Ethica = () => {
  const [products, setProducts] = useState([]);
  const [isShowOpen, setIsShowOpen] = useState({ show1: false, show2: false });
  const [sortOrder, setSortOrder] = useState("Latest Update");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [productsPerPage, setProductsPerPage] = useState(10);

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await getProductsEthica();
      setProducts(data);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    const paginated = products.slice(startIndex, endIndex);
    setPaginatedProducts(paginated);
  }, [currentPage, products, productsPerPage]);

  const changeProductsPerPage = (perPage) => {
    setProductsPerPage(perPage);
    // setCurrentPage(1);
  };
  const toggleSortDropdown = (dropdown) => {
    setIsShowOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };
  const sortProducts = (order) => {
    setSortOrder(order);
    const sortedProducts = [...paginatedProducts].sort((a, b) => {
      switch (order) {
        case "Low to High":
          return a.harga - b.harga;
        case "High to Low":
          return b.harga - a.harga;
        case "A - Z":
          return a.brand.localeCompare(b.brand);
        case "Z - A":
          return b.brand.localeCompare(a.brand);
        default:
          return new Date(b.tahun) - new Date(a.tahun);
      }
    });
    setPaginatedProducts(sortedProducts);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(products.length / productsPerPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPages = 5;

    if (totalPages <= maxPages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPages - 1) {
        for (let i = 1; i <= maxPages; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - maxPages + 1) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - maxPages + 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };
  return (
    <section id="home">
      <main class="main">
        <div class="page-header mt-30 mb-50">
          <div class="container">
            <div class="archive-header">
              <div class="row align-items-center">
                <div class="col-xl-4">
                  <h1 class="mb-15">Ethica</h1>
                  <div class="breadcrumb">
                    <a href="/" rel="nofollow">
                      <i class="fi-rs-home mr-5"></i>Beranda
                    </a>
                    <span></span> Ethica
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mb-30">
          <div class="row">
            <div class="col-12">
              <div class="shop-product-fillter">
                <div class="totall-product">
                  <p>
                    We found{" "}
                    <strong class="text-brand">{products.length}</strong> items
                    for you!
                  </p>
                </div>
                <div class="sort-by-product-area">
                  <div
                    class="sort-by-cover mr-10"
                    onClick={() => toggleSortDropdown("show1")}
                  >
                    <div class="sort-by-product-wrap">
                      <div class="sort-by">
                        <span>
                          <i class="fi-rs-apps"></i>Show:
                        </span>
                      </div>
                      <div class="sort-by-dropdown-wrap">
                        <span>
                          {" "}
                          {productsPerPage}{" "}
                          <i class="fi-rs-angle-small-down"></i>
                        </span>
                      </div>
                    </div>
                    {isShowOpen.show1 && (
                      <div className="sort-by-dropdown show">
                        <ul>
                          <li>
                            <a
                              className={productsPerPage === 10 ? "active" : ""}
                              href="#"
                              onClick={() => changeProductsPerPage(10)}
                            >
                              10
                            </a>
                          </li>
                          <li>
                            <a
                              className={productsPerPage === 25 ? "active" : ""}
                              href="#"
                              onClick={() => changeProductsPerPage(25)}
                            >
                              25
                            </a>
                          </li>
                          <li>
                            <a
                              className={productsPerPage === 50 ? "active" : ""}
                              href="#"
                              onClick={() => changeProductsPerPage(50)}
                            >
                              50
                            </a>
                          </li>
                          <li>
                            <a
                              className={productsPerPage === 75 ? "active" : ""}
                              href="#"
                              onClick={() => changeProductsPerPage(75)}
                            >
                              75
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                productsPerPage === 100 ? "active" : ""
                              }
                              href="#"
                              onClick={() => changeProductsPerPage(100)}
                            >
                              100
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    class="sort-by-cover"
                    onClick={() => toggleSortDropdown("show2")}
                  >
                    <div class="sort-by-product-wrap">
                      <div class="sort-by">
                        <span>
                          <i class="fi-rs-apps-sort"></i>Sort by:
                        </span>
                      </div>
                      <div class="sort-by-dropdown-wrap">
                        <span>
                          {" "}
                          {sortOrder} <i class="fi-rs-angle-small-down"></i>
                        </span>
                      </div>
                    </div>
                    {isShowOpen.show2 && (
                      <div className="sort-by-dropdown show">
                        <ul>
                          <li>
                            <a
                              className={
                                sortOrder === "Latest Update" ? "active" : ""
                              }
                              onClick={() => sortProducts("Latest Update")}
                            >
                              Latest Update
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                sortOrder === "Low to High" ? "active" : ""
                              }
                              onClick={() => sortProducts("Low to High")}
                            >
                              Price: Low to High
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                sortOrder === "High to Low" ? "active" : ""
                              }
                              onClick={() => sortProducts("High to Low")}
                            >
                              Price: High to Low
                            </a>
                          </li>
                          <li>
                            <a
                              className={sortOrder === "A - Z" ? "active" : ""}
                              onClick={() => sortProducts("A - Z")}
                            >
                              A - Z
                            </a>
                          </li>
                          <li>
                            <a
                              className={sortOrder === "Z - A" ? "active" : ""}
                              onClick={() => sortProducts("Z - A")}
                            >
                              Z - A
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="row product-grid">
                {paginatedProducts.length > 0 ? (
                  paginatedProducts.map((product, idx) => (
                    <div class="col-lg-1-5 col-md-4 col-12 col-sm-6">
                      <div class="product-cart-wrap mb-30">
                        <div class="product-img-action-wrap">
                          <div class="product-img product-img-zoom">
                            <a href={`/product-ethica/${product.artikel}`}>
                              <img
                                class="default-img"
                                src={product.gambar.besar}
                                alt={product.artikel}
                              />
                            </a>
                          </div>

                          <div class="product-badges product-badges-position product-badges-mrg">
                            <span class="hot">Hot</span>
                          </div>
                        </div>
                        <div class="product-content-wrap">
                          <div class="product-category"></div>
                          <h2>
                            <a href={`/product-ethica/${product.artikel}`}>
                              {product.artikel}
                            </a>
                          </h2>
                          <div>
                            {/* <span class="font-small text-muted">
                              <a
                                href=""
                                onClick={() =>
                                  setCategoryCookie(product.item_category_id)
                                }
                              >
                                {category
                                  .filter(
                                    (item) =>
                                      item.category_id ===
                                      product.item_category_id
                                  )
                                  .map((item) => item.category_name)}
                              </a>
                            </span> */}
                          </div>
                          <div class="product-card-bottom">
                            <div class="product-price">
                              <span>{formatPrice(product.harga)}</span>
                            </div>
                            <div class="add-cart">
                              <a
                                class="add"
                                href={`/product-ethica/${product.artikel}`}
                              >
                                <i class="fi-rs-shopping-cart mr-5"></i>Add{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center pt-50 pb-50">
                    <h3>Mohon tunggu, produk akan muncul sebentar lagi...</h3>
                  </div>
                )}
              </div>
              {/* <!--product grid--> */}
              <div className="pagination-area mt-20 mb-20">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-start">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="fi-rs-arrow-small-left"></i>
                      </a>
                    </li>
                    {getPageNumbers().map((page, idx) =>
                      page === "..." ? (
                        <li className="page-item" key={idx}>
                          <span className="page-link">...</span>
                        </li>
                      ) : (
                        <li
                          key={idx}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </a>
                        </li>
                      )
                    )}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="fi-rs-arrow-small-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};
