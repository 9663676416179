import { useEffect, useState } from "react";
import { dataArtikel } from "../../services/data";
import { getBlog, urlAPI } from "../../services/api.services";
import { error } from "jquery";

export const Artikel = () => {
  const [article, setArticle] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false); // Untuk toggle view
  const [visibleCount, setVisibleCount] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBlog();
        setArticle(data);
      } catch {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleToggle = () => {
    if (isExpanded) {
      setVisibleCount(6); // Tampilkan 6 artikel
    } else {
      setVisibleCount(article.length); // Tampilkan semua artikel
    }
    setIsExpanded(!isExpanded); // Toggle state
  };

  // Memotong artikel berdasarkan jumlah yang ingin ditampilkan
  const displayedArticles = article.slice(0, visibleCount);

  return (
    <div>
      <main class="main pages">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span> Artikel
            </div>
          </div>
        </div>
        <div class="page-content ml-lg-50 mr-lg-50 ml-md-50 mr-md-50 pt-20 pt-md-5 pb-150">
          <div className="container">
            <h3 className="text-center pb-20">Artikel</h3>
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="d-flex flex-column gap-2">
                  <p>Baca Artikel Terkini</p>
                  <h4>Semua Artikel</h4>
                </div>
                <div className="row pt-20">
                  {displayedArticles.map((item) => (
                    <div className="col-md-6 col-lg-4 mb-20">
                      <div className="p-10">
                        <img
                          src={urlAPI + item.image}
                          alt={item.title}
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "contain",
                          }}
                        />

                        <div className="d-flex flex-column gap-1 p-1">
                          <p>
                            {new Date(item.updated_at).toLocaleDateString()}
                          </p>
                          <hr />
                          <h5>{item.title}</h5>
                          <p
                            class="text-truncate"
                            title={item.description.replace(/<\/?p>/g, "")}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            {item.description.replace(/<\/?p>/g, "")}
                          </p>
                          <a href={`/artikel/${item.id}`}>Selangkapnya..</a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="btn" onClick={handleToggle}>
                    {isExpanded ? "Tampilkan Lebih Sedikit" : "Tampilkan Lebih"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
