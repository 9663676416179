import React, { useState, useEffect } from "react";
import {
  addReview,
  deleteReview,
  getDetailPesanan,
  getListReview,
  getReviewById,
  updateReview,
} from "../../../services/api.services";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertComponent, { useAlert } from "../alert";
import { userInfo } from "../../../pages/Account/AuthProvider";

const initialReviews = [
  {
    product_item_id: "",
    item_name: "",
    order_id: "",
    review: "",
    rating: 0,
    name: "",
    user_id: "",
  },
];

const StarIcon = ({ filled, onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={filled ? "#b83bb3" : "#e4e5e9"}
    width="26px"
    height="26px"
    style={{ cursor: "pointer" }}
  >
    <path d="M12 .587l3.668 7.568 8.332 1.151-6.001 5.601 1.415 8.255L12 18.897l-7.414 4.265L6 14.907 0 9.306l8.332-1.151z" />
  </svg>
);

export const Review = () => {
  const { id } = useParams();
  const [reviews, setReviews] = useState(initialReviews);
  const [loading, setLoading] = useState(false);
  const [alert, showAlert] = useAlert();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await getDetailPesanan(id);
        const fetchedReviews = response.items.map((item) => ({
          product_item_id: item.id,
          item_name: item.item_name,
          order_id: response.salesorder_id,
          review: "",
          rating: 0,
          name: userInfo.user.name || "", // Menggunakan userInfo untuk mengisi nama
          user_id: userInfo.user.id || "", // Menggunakan userInfo untuk mengisi ID pengguna
        }));
        setReviews(fetchedReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    if (id) {
      fetchReviews();
    }
  }, [id]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newReviews = [...reviews];
    newReviews[index][name] = value;
    setReviews(newReviews);
  };

  const handleRatingChange = (index, rating) => {
    const newReviews = [...reviews];
    newReviews[index].rating = rating;
    setReviews(newReviews);
  };

  const allReviewsFilled = () => {
    return reviews.every(
      (review) =>
        review.product_item_id &&
        review.item_name &&
        review.order_id &&
        review.rating > 0
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await addReview(reviews);
      showAlert("Review berhasil ditambahkan!", "success");
      window.location.href = `/review-list/${id}`;
      setReviews(initialReviews);
    } catch (error) {
      console.error("Error submitting reviews:", error);
      showAlert("Failed to submit reviews. Please try again.", "danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertComponent alert={alert} />
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span>
              <a href="/account" rel="nofollow">
                <i className="fi-rs-user mr-5 ml-5"></i>akun
              </a>
              <span></span> Review
            </div>
          </div>
        </div>

        <div className="page-content pt-20 pt-md-5 pb-150">
          <div className="container-xl">
            <div className="pl-10 pr-10">
              <div className="card">
                <div className="card-header d-flex align-items-center gap-3">
                  <a href="/account">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#b83bb3"
                      class="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                      />
                    </svg>
                  </a>
                  <h4 className="mb-0">Tambah Review</h4>
                </div>
                <div className="card-body">
                  {/* {reviewData.length > 0 ? (
                  <div>
                    <ReviewList reviewData={reviewData} />
                  </div>
                ) : ( */}
                  <form onSubmit={handleSubmit}>
                    {reviews.map((review, index) => (
                      <>
                        <div key={index} className="row mb-4">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="number"
                                name="product_item_id"
                                value={review.product_item_id}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Product Item ID*"
                                required
                                hidden
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="item_name"
                                value={review.item_name}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Nama Barang*"
                                required
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="number"
                                name="order_id"
                                value={review.order_id}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="ID Pesanan*"
                                required
                                hidden
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group d-flex align-items-center gap-3">
                              <h6 className="mb-0">Rating *</h6>
                              <div className="rating d-flex">
                                {[...Array(5)].map((star, i) => {
                                  const ratingValue = i + 1;
                                  return (
                                    <StarIcon
                                      key={i}
                                      filled={ratingValue <= review.rating}
                                      onClick={() =>
                                        handleRatingChange(index, ratingValue)
                                      }
                                    />
                                  );
                                })}
                              </div>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                value={review.name}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Nama(Optional)"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <textarea
                                name="review"
                                value={review.review}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Ulasan Pesanan"
                                rows="5"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="number"
                                name="user_id"
                                value={review.user_id}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="User ID(Optional)"
                                className="form-control"
                                hidden
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                    <button
                      className="btn submit submit-auto-width"
                      type="submit"
                      disabled={loading || !allReviewsFilled()}
                    >
                      {loading ? "Submitting..." : "Simpan"}
                    </button>
                    <button
                      className="btn bg-white"
                      onClick={() => (window.location.href = `/account`)}
                      type="button"
                      style={{
                        marginLeft: "10px",
                        border: "1px solid #b83bb3",
                        borderRadius: "5px",
                        color: "#b83bb3",
                      }}
                    >
                      Batal
                    </button>
                  </form>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// REVIEW LIST
export const ReviewList = () => {
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, showAlert] = useAlert();

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const response = await getListReview();
        setReviewData(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReview();
  }, []);
  const handleEdit = (id) => {
    window.location.href = `/review/edit/${id}`;
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteReview(id);
      if (response.success) {
        showAlert(
          response.message,
          "success",
          response.message || "Review deleted successfully!"
        );
        // Tambahkan logika untuk mengupdate UI setelah penghapusan jika diperlukan
        setReviewData(reviewData.filter((review) => review.id !== id));
      } else {
        showAlert(
          response.message,
          "danger",
          response.message || "Failed to delete review."
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AlertComponent alert={alert} />
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span>
              <a href="/account" rel="nofollow">
                <i className="fi-rs-user mr-5 ml-5"></i>akun
              </a>
              <span></span> Review
            </div>
          </div>
        </div>

        <div className="page-content pt-20 pt-md-5 pb-150">
          <div className="container-xl">
            <div className="pl-10 pr-10">
              <div className="card">
                <div className="card-header d-flex align-items-center gap-3 ">
                  <a href="/account">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#b83bb3"
                      class="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                      />
                    </svg>
                  </a>
                  <h4 className="mb-0">Review Pesanan</h4>
                </div>
                <div className="card-body">
                  {loading ? (
                    <div className="w-full text-center text-secondary pb-50 pt-50">
                      Mohon tunggu, list review masih loading
                    </div>
                  ) : (
                    <>
                      {reviewData.length === 0 ? (
                        <div className="w-full text-center text-secondary pb-50 pt-50">
                          Mohon tunggu, list review belum ada
                        </div>
                      ) : (
                        reviewData.map((review) => (
                          <div
                            key={review.id}
                            className="border p-3 rounded-3 my-3"
                          >
                            <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                              <div className="d-flex flex-column gap-2">
                                <h6>{review.name}</h6>
                                <h7 className="text-dark">
                                  {new Date(
                                    review.created_at
                                  ).toLocaleDateString()}
                                </h7>
                                <div className="d-flex gap-1">
                                  {[...Array(parseInt(review.rating, 10))].map(
                                    (_, index) => (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="#b83bb3"
                                        className="bi bi-star-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                      </svg>
                                    )
                                  )}
                                </div>
                                <h7 className="text-dark">
                                  Varian : {review.item_name}
                                </h7>
                                <h6 className="text-dark">{review.review}</h6>
                              </div>
                              <div className="d-flex gap-2">
                                <button
                                  disabled={review.is_reviewed !== "1"}
                                  className="btn btn-sm"
                                  onClick={() => handleEdit(review.id)}
                                >
                                  Edit
                                </button>
                                <button
                                  disabled={review.is_reviewed !== "1"}
                                  className="btn-sm btn text-capitalize"
                                  onClick={() => handleDelete(review.id)}
                                >
                                  hapus
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// EDIT REVIEW
export const EditReview = () => {
  const { id } = useParams();
  const [reviewData, setReviewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, showAlert] = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchReviewById = async () => {
      try {
        const response = await getReviewById(id);
        setReviewData(response);
      } catch (err) {
        console.log(err);
      }
    };

    fetchReviewById();
    setLoading(false);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReviewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRatingChange = (rating) => {
    setReviewData((prevData) => ({
      ...prevData,
      rating: rating,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await updateReview(id, reviewData); // Pastikan Anda mengirimkan `id` dan `reviewData`
      showAlert("Review berhasilar diperbarui!", "success");
      window.location.href = `/review-list/${id}`; // Pindahkan ke halaman review-list setelah berhasil
    } catch (error) {
      showAlert("Failed to submit review. Please try again.", "danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertComponent alert={alert} />
      <main className="main pages">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a href="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span>
              <a href="/account" rel="nofollow">
                <i className="fi-rs-user mr-5 ml-5"></i>akun
              </a>
              <span></span> Review
            </div>
          </div>
        </div>

        <div className="page-content pt-20 pt-md-5 pb-150">
          <div className="container-xl">
            <div className="pl-10 pr-10">
              <div className="card">
                <div className="card-header d-flex align-items-center gap-3">
                  <a href={`/review-list/${id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#b83bb3"
                      class="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                      />
                    </svg>
                  </a>
                  <h4 className="mb-0">Perbarui Review</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            name="product_item_id"
                            value={reviewData.product_item_id}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Product Item ID*"
                            required
                            hidden
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="item_name"
                            value={reviewData.item_name}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Nama Barang*"
                            required
                            readOnly
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="order_id"
                            value={reviewData.order_id}
                            onChange={(e) => handleInputChange(e)}
                            hidden
                            placeholder="ID Pesanan*"
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group d-flex align-items-center gap-3">
                          <h6 className="mb-0">Rating *</h6>
                          <div className="rating d-flex">
                            {[...Array(5)].map((star, i) => {
                              const ratingValue = i + 1;
                              return (
                                <StarIcon
                                  key={i}
                                  filled={ratingValue <= reviewData.rating}
                                  onClick={() =>
                                    handleRatingChange(ratingValue)
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            value={reviewData.name}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Nama(Optional)"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            name="review"
                            value={reviewData.review}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="Ulasan Pesanan"
                            rows="5"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            name="user_id"
                            value={reviewData.user_id}
                            onChange={(e) => handleInputChange(e)}
                            placeholder="User ID(Optional)"
                            className="form-control"
                            hidden
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <button
                        className="btn submit submit-auto-width"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Simpan"}
                      </button>
                      <button
                        className="btn bg-white"
                        onClick={() =>
                          (window.location.href = `/review-list/${id}`)
                        }
                        type="button"
                        style={{
                          marginLeft: "10px",
                          border: "1px solid #b83bb3",
                          borderRadius: "5px",
                          color: "#b83bb3",
                        }}
                      >
                        Batal
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
