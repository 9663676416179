// Select2Component.js
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import { apiFunctions, postData } from '../../../services/api.services';
import { Form } from 'react-bootstrap';

const SelectAllCategory = ({showAlert}) => {
    const [category, setCategory] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(() => {
        apiFunctions.category((data) => {
            setCategory(data)
        });
        apiFunctions.categoryActive((data) => {
            const activeCategoryIds = data.map(item => item.category_id);
            setSelectedCategories(activeCategoryIds);
            $('#select-category').val(activeCategoryIds).trigger('change');
        });
        $('#select-category').select2({
            placeholder: "Pilih Kategori",
            maximumSelectionLength: 8,
        }).on('change', function () {
            const selected = $(this).val();
            setSelectedCategories(selected);
        });
        return () => {
            $('#select-category').select2('destroy');
        };
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const activeCategories = JSON.stringify(selectedCategories);
        postData('jubelio/categories/active', { active_categories: activeCategories })
            .then(response => {
                showAlert('Setting Kategori berhasil', 'success');
            })
            .catch(error => {
                console.error('Error:', error);
                showAlert('Setting Kategori gagal', 'danger');
            });
    };

    return (
        <>
        <form onSubmit={handleSubmit}>
            <select id="select-category" multiple="multiple" style={{ width: '100%' }}>
                {category.length > 0 && category.map((item)=>(
                    <option key={item.category_id} value={item.category_id}>{item.category_name}</option>
                ))
                }
            </select>
            <button className="d-block submit submit-auto-width mt-10 btn-sm" type="submit">Set</button>
        </form>
        </>
    );
};

export default SelectAllCategory;

export const SelectActiveCategory = ({value}) => {
    const [categoryActive, setCategoryActive] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(() => {
        apiFunctions.categoryActive((data) => {
            setCategoryActive(data)
        });
        apiFunctions.categoryActive((data) => {
            const activeCategoryIds = data.map(item => item.category_id);
            setSelectedCategories(activeCategoryIds);
        });
        $('#active-category').select2({
            placeholder: "Pilih Kategori",
            maximumSelectionLength: 8,
        }).on('change', function () {
            const selected = $(this).val();
            setSelectedCategories(selected);
        });
        return () => {
            $('#active-category').select2('destroy');
        };
    }, []);

    return (
        <>
        <Form.Control as="select" value={value}>
        {/* <Form.Control as="select" value={value} onChange={handleFormChange}> */}
            {categoryActive.length > 0 && categoryActive.map((item)=>(
                <option key={item.category_id} value={item.category_id}>{item.category_name}</option>
            ))
            }
        </Form.Control>
        </>
    );
};