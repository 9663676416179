export const formatPrice = (price) => {
  const numericPrice = parseFloat(price);
  if (!isNaN(numericPrice)) {
    const formattedPrice = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numericPrice);
    return formattedPrice;
  }
  return price;
};

export const setCategoryCookie = (categoryId) => {
  if (categoryId === null) {
    document.cookie = "category_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/product;";
  } else {
    document.cookie = `category_id=${categoryId}; path=/product`;
  }
  window.location.href = '/product';
};
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

function importAll(r) {
  let images = {};
  r.keys().forEach((key) => {
    images[key] = r(key);
  });
  return images;
}

export  { importAll};
  