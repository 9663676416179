import React from "react";
import Footer from "../../components/layouts/Footer.jsx";
import Navbar from "../../components/layouts/NavbarLP/NavbarLP.jsx";
import FloatButton from "../../components/commons/buttons/BtnFloat.jsx";
import LandingPageProduct from "./landingPage.jsx";

const LandingPage = () => {
  return (
    <div>
      <FloatButton />
      <Navbar />
      <LandingPageProduct/>
      <Footer />
    </div>
  );
};

export default LandingPage;
