import { addEthicaProductToCart } from "../../services/api.services";
import { userInfo } from "../Account/AuthProvider";

export const addToCart = (
  product,
  quantity,
  item_group,
  productGroup,
  showAlert
) => {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const existingItemIndex = cart.findIndex(
    (item) => item.item_id === product.item_id
  );
  const item_name = productGroup.item_group_name;
  const package_weight = productGroup.package_weight;
  const package_height = productGroup.package_height;
  const package_width = productGroup.package_width;
  const package_length = productGroup.package_length;

  if (existingItemIndex !== -1) {
    const newQuantity = cart[existingItemIndex].quantity + quantity;
    if (newQuantity <= product.end_qty) {
      cart[existingItemIndex].quantity = newQuantity;
    } else {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
      return;
    }
  } else {
    if (quantity <= product.end_qty) {
      cart.push({
        ...product,
        quantity,
        item_group,
        item_name,
        package_weight,
        package_height,
        package_width,
        package_length,
      });
    } else {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
      return;
    }
  }

  localStorage.setItem("cart", JSON.stringify(cart));
  showAlert("Produk ditambahkan ke keranjang.", "success");

  const event = new Event("cartUpdated");
  window.dispatchEvent(event);
};



export const getCartItems = () => {
  return JSON.parse(localStorage.getItem("cart")) || [];
};

export const getCartItemsEthica = () => {
  return JSON.parse(localStorage.getItem("cart-ethica")) || [];
};

export const updateCartItemQuantity = (itemId, newQuantity) => {
  const cart = getCartItems();
  const itemIndex = cart.findIndex((item) => item.item_id === itemId);

  if (itemIndex !== -1) {
    if (newQuantity <= 0) {
      cart.splice(itemIndex, 1); // Remove item if quantity is 0 or less
    } else {
      cart[itemIndex].quantity = newQuantity;
    }

    localStorage.setItem("cart", JSON.stringify(cart));
  }
};

export const getCartSubtotal = () => {
  const cart = getCartItems();
  return cart.reduce(
    (total, item) => total + item.quantity * item.sell_price,
    0
  );
};

export const getCartSubtotalEthica = () => {
  const cart = getCartItemsEthica();
  return cart.reduce(
    (total, item) => total + item.quantity * parseFloat(item.harga),
    0
  );
};

export const getCartTotalQuantity = () => {
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  return cart.reduce((total, item) => total + item.quantity, 0);
};

export const getCartEthicaTotalQuantity = () => {
  const cart = JSON.parse(localStorage.getItem("cart-ethica")) || [];
  return cart.reduce((total, item) => total + item.quantity, 0);
};
