import React from "react";
import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Home from "./pages/Katalog";
import Layout from "./components/layouts";
import Product from "./pages/Products";
import Account from "./pages/Account";
import Cart from "./pages/Cart";
import Login from "./pages/Account/Login";
import NotFound from "./pages/NotFound";
import PaymentOrder from "./pages/Payment";
import Register from "./pages/Account/Register";
import { SearchProvider } from "./pages/Search/SearchContext";
import ResultsComponent from "./pages/Search";
import { AddReview, ListReview } from "./pages/Review";
import { EditReview } from "./components/commons/review";
import { EthicaProducts } from "./pages/Products/ethica-product";
import { Ethica } from "./pages/Katalog/ethica";
import { Artikel } from "./pages/Artikel";
import { OurStory } from "./pages/OurStory";
import { DetailArtikel } from "./pages/Artikel/detailAtrikel";
import { TambahArtikel } from "./pages/Artikel/formArtikel";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage}></Route>
          {/* <Route path="/admin/login" ></Route> */}
          <SearchProvider>
            <Layout>
              <Switch>
                <Route
                  path="/search-results"
                  exact
                  component={ResultsComponent}
                ></Route>
                <Route path="/login" exact component={Login}></Route>
                <Route path="/register" exact component={Register}></Route>
                <Route path="/artikel" exact component={Artikel}></Route>
                <Route
                  path="/artikel/:id"
                  exact
                  component={DetailArtikel}
                ></Route>
                <Route
                  path="/artikel/tambah"
                  exact
                  component={TambahArtikel}
                ></Route>
                <Route path="/tentang-kami" exact component={OurStory}></Route>
                <Route path="/product/:id" exact component={Product}></Route>
                <Route path="/product" exact component={Home}></Route>
                <Route path="/product-ethica" exact component={Ethica}></Route>
                <Route
                  path="/product-ethica/:nama_artikel"
                  exact
                  component={EthicaProducts}
                ></Route>
                <Route path="/account" exact component={Account}></Route>
                <Route path="/cart" exact component={Cart}></Route>
                <Route path="/payment-success" exact component={PaymentOrder} />
                <Route path="/payment-failed" exact component={PaymentOrder} />
                <Route path="/review/:id" exact component={AddReview} />
                <Route path="/review-list/:id" exact component={ListReview} />
                <Route path="/review/edit/:id" exact component={EditReview} />
                <Route path="*" component={NotFound}></Route>
              </Switch>
            </Layout>
          </SearchProvider>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
