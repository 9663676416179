// src/components/AlertComponent.js
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

const AlertComponent = ({ alert }) => {
  return (
    alert.visible && (
      <Alert variant={alert.type} className="position-fixed top-0 start-50 translate-middle-x mt-3" style={{zIndex:99999}}>
        {alert.message}
      </Alert>
    )
  );
};

export default AlertComponent;

export function useAlert() {
    const [alert, setAlert] = useState({ message: '', type: '', visible: false });
  
    const showAlert = (message, type = 'warning') => {
      setAlert({ message, type, visible: true });
      setTimeout(() => {
        setAlert({ message: '', type: '', visible: false });
      }, 5000);
    };
  
    return [alert, showAlert];
}