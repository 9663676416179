import React, { useEffect, useState } from "react";
import { FaXTwitter } from "react-icons/fa6";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

import loadImg from '../../assets/imgs/theme/loadingImg.gif';

import { apiFunctions, fetchData, urlAPI } from "../../services/api.services";
import { Logo, usePayement, useShipping } from "../../assets/images";

const Footer = () => {
  const paymentImages = usePayement();
  const shippingImages = useShipping();
  const [footerMenu, setFooterMenu] = useState(null);
  const [footerSet, setFooterSet] = useState(null);
  const [payment, setPayment] = useState(null);
  const [paymentName, setPaymentName] = useState(null);
  const [shipment, setShipment] = useState(null);
  const [shipmentName, setShipmentName] = useState(null);
  const time = new Date();
  useEffect(() => {
    fetchData('footer/menu', '', (data) => {
      const footer = data.data 
      setFooterMenu(footer.menus)
      setFooterSet(footer.settings)
    });
    fetchData('footer/payment', '', (data) => {
      const payment = data.data 
      setPayment(payment.paymentMethods)
      setPaymentName(payment.settings)
    });
    fetchData('footer/shipment', '', (data) => {
      const shipment = data.data 
      setShipment(shipment.shipmentMethods)
      setShipmentName(shipment.settings)
    });
  }, []);

  return (
  <>
    <footer>
      <div class="container py-4">
        <div className="text-center w-50 mx-auto pb-4">
          <a href="/">
            <img src={Logo} alt="" className="w-25"/>
          </a>
        </div>
        <div class="row pt-20 px-5 pb-20">
          <div class="col-md-3 mx-auto">
            <h6>INFO</h6>
            {footerMenu &&
            footerMenu.map((item)=>(
              <a href={item.url} class="d-flex my-3 text-dark">{item.title}</a>
            ))
            }
          </div>
          <div class="col-md-3 mx-auto">
            {payment && paymentName &&
            <>
            <h6>{paymentName.payment_menu}</h6>
            {payment.map((item) => 
            <img src={urlAPI+item.image_url} alt="" height='40px' width='60px' class="my-1 me-1" style={{objectFit:'contain'}}/>
            )}
            </>
            }
            {shipment && shipmentName &&
            <>
            <h6>{shipmentName.shipment_menu}</h6>
            {shipment.map((item) => 
            <img src={urlAPI+item.image_url} alt="" height='40px' width='60px' class="my-1 me-1" style={{objectFit:'contain'}}/>
            )}
            </>
            }
          </div>
          <div class="col-md-3 mx-auto">
            {footerSet &&
            <>
            <h6>{footerSet.store_name}</h6>
            <div dangerouslySetInnerHTML={{ __html: footerSet.store_description }} />
            </>
            }
          </div>
        </div>
        <div className='pt-2 text-center'>{time.getFullYear()} &copy;  {footerSet && footerSet.store_name}</div>
      </div>
    </footer>
    {/* <!-- Preloader Start --> */}
    {/* <div id="preloader-active">
      <div className="preloader d-flex align-items-center justify-content-center">
        <div className="preloader-inner position-relative">
          <div className="text-center">
            <img src={loadImg} alt="" width={'30%'}/>
          </div>
        </div>
      </div>
    </div> */}
    
  </>
  );
};

export default Footer;
