import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  apiFunctions,
  fetchProductByGroupId,
  getRating,
} from "../../services/api.services";
import { formatPrice, setCategoryCookie } from "../../services/function";

// import $ from 'jquery'
import Slider from "react-slick";
import { addToCart } from "../Cart/Cart";
import AlertComponent, { useAlert } from "../../components/commons/alert";
import { userInfo } from "../Account/AuthProvider";
import {
  RatingAverage,
  RatingFilter,
  RatingList,
} from "../../components/commons/rating";
import { ratingData } from "../../services/data";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function Products() {
  const { id } = useParams();
  const [alert, showAlert] = useAlert();
  const [ProductsVal, setProducts] = useState([]);
  const [CategoryVal, setCategory] = useState([]);
  const [Variant, setVariant] = useState([]);
  const [imgVar, setImgVar] = useState([]);
  const [RelatedVal, setRelated] = useState([]);
  const imageSliderRef = useRef(null);
  const thumbnailsRef = useRef(null);

  const [selectedVar, setSelectedVar] = useState(null);
  const categoryName = CategoryVal.filter(
    (category) => category.category_id === ProductsVal.item_category_id
  ).map((item) => item.category_name);

  useEffect(() => {
    fetchProductByGroupId(id, (data) => {
      setProducts(data);
      setVariant(data.product_skus || []);
      setSelectedVar(data.product_skus ? data.product_skus[0] : null);
      const imgVar = data.product_skus
        .filter((item) => item.images && item.images[0])
        .map((item) => item.images[0]);
      const a = imgVar.slice(0, 10);
      // setImgVar(a)
      setImgVar(imgVar);

      const categoryId = data ? data.item_category_id : null;
      if (categoryId) {
        apiFunctions.products((productsData) => {
          const related = productsData.data
            .filter((item) => item.item_category_id === categoryId)
            .sort(
              (a, b) => new Date(b.last_modified) - new Date(a.last_modified)
            );
          setRelated(related);
        });
      }
    });
    apiFunctions.category((data) => {
      setCategory(data);
    });
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  const description = ProductsVal.description || ""; // Ensure description is defined
  const shortDescription = description.slice(0, 100);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (imgVar.length > 0) {
      if (window.$ && window.$.fn.slick) {
        const $imageSlider = window.$(imageSliderRef.current);
        const $thumbnails = window.$(thumbnailsRef.current);

        if ($imageSlider.length && $thumbnails.length) {
          $imageSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            asNavFor: ".slider-nav-thumbnails",
          });

          $thumbnails.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: ".product-image-slider",
            dots: false,
            focusOnSelect: true,
            prevArrow:
              '<button type="button" class="slick-prev"><i class="fi-rs-arrow-small-left"></i></button>',
            nextArrow:
              '<button type="button" class="slick-next"><i class="fi-rs-arrow-small-right"></i></button>',
          });

          $imageSlider.on(
            "beforeChange",
            function (event, slick, currentSlide, nextSlide) {
              var mySlideNumber = nextSlide;
              $thumbnails.find(".slick-slide").removeClass("slick-active");
              $thumbnails
                .find(".slick-slide")
                .eq(mySlideNumber)
                .addClass("slick-active");
            }
          );

          $imageSlider.on(
            "beforeChange",
            function (event, slick, currentSlide, nextSlide) {
              var img = window.$(slick.$slides[nextSlide]).find("img");
              window.$(".zoomWindowContainer,.zoomContainer").remove();
              if (window.$(window).width() > 768) {
                window.$(img).elevateZoom({
                  zoomType: "inner",
                  cursor: "crosshair",
                  zoomWindowFadeIn: 500,
                  zoomWindowFadeOut: 750,
                });
              }
            }
          );

          if (window.$(".product-image-slider").length) {
            if (window.$(window).width() > 768) {
              $imageSlider.find(".slick-active img").elevateZoom({
                zoomType: "inner",
                cursor: "crosshair",
                zoomWindowFadeIn: 500,
                zoomWindowFadeOut: 750,
              });
            }
          }
        } else {
          console.error("Slick carousel or jQuery is not loaded");
        }
      }
    }
    return () => {
      if (
        imageSliderRef.current &&
        window.$(imageSliderRef.current).hasClass("slick-initialized")
      ) {
        window.$(imageSliderRef.current).slick("unslick");
      }
      if (
        thumbnailsRef.current &&
        window.$(thumbnailsRef.current).hasClass("slick-initialized")
      ) {
        window.$(thumbnailsRef.current).slick("unslick");
      }
    };
    // window.$(".product-image-slider").not('.slick-initialized').slick()
    // window.$(".slider-nav-thumbnails").not('.slick-initialized').slick()
  }, [imgVar]);

  //add to cart
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (increment) => {
    const newQuantity = quantity + increment;
    if (newQuantity >= 1 && newQuantity <= selectedVar.end_qty) {
      setQuantity(newQuantity);
    } else if (newQuantity > selectedVar.end_qty) {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
    }
  };

  const handleQuantityInputChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 1 && value <= selectedVar.end_qty) {
      setQuantity(value);
    } else if (value > selectedVar.end_qty) {
      showAlert("Anda telah mencapai stok maksimum.", "danger");
      setQuantity(selectedVar.end_qty); // Set to max stock
    } else if (value < 1) {
      setQuantity(1); // Set to minimum stock
    }
  };

  const handleAddToCart = () => {
    addToCart(selectedVar, quantity, id, ProductsVal, showAlert);
  };

  const handleVariantClick = (variant) => {
    setSelectedVar(variant);
    setQuantity(1);
    setImgVar(variant);
  };

  const [selectedRating, setSelectedRating] = useState(null);
  const [ratingData, setRatingData] = useState([]);

  useEffect(() => {
    const fetchRating = async () => {
      try {
        const response = await getRating(id, selectedRating);
        if (Array.isArray(response)) {
          setRatingData(response);
        } else {
          console.error("Response is not an array");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchRating();
  }, [id, selectedRating]);

  return (
    <section id="product">
      <AlertComponent alert={alert} />
      <main class="main">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span>{" "}
              <a
                onClick={() => setCategoryCookie(ProductsVal.item_category_id)}
              >
                {categoryName}
              </a>{" "}
              <span></span> {ProductsVal.item_group_name}
            </div>
          </div>
        </div>
        <div class="container mb-75">
          <div class="row">
            <div class="col-xl-10 col-lg-12 m-auto">
              <div class="product-detail accordion-detail">
                {ProductsVal.length !== 0 ? (
                  <div class="row mb-50 mt-30">
                    <div class="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                      {imgVar === selectedVar ? (
                        <div class="detail-gallery">
                          <span class="zoom-icon">
                            <i class="fi-rs-search"></i>
                          </span>

                          <figure className="border-radius-10">
                            {selectedVar.images &&
                            selectedVar.images.length > 0 ? (
                              <img
                                src={selectedVar.images[0].cloud_key || ""}
                                alt="product"
                              />
                            ) : (
                              <div>Gambar tidak tersedia</div>
                            )}
                          </figure>
                        </div>
                      ) : (
                        imgVar !== selectedVar &&
                        imgVar.length > 0 && (
                          <div class="detail-gallery">
                            <span class="zoom-icon">
                              <i class="fi-rs-search"></i>
                            </span>

                            <div
                              class="product-image-slider"
                              ref={imageSliderRef}
                            >
                              {imgVar.map((item, index) => (
                                <figure
                                  key={index}
                                  className="border-radius-10"
                                >
                                  <img
                                    src={
                                      imgVar.length > 0 ? item.cloud_key : ""
                                    }
                                    alt="product image"
                                  />
                                </figure>
                              ))}
                            </div>

                            <div
                              class="slider-nav-thumbnails"
                              ref={thumbnailsRef}
                            >
                              {imgVar.map((item, index) => (
                                <div key={index}>
                                  <img
                                    src={
                                      imgVar.length > 0 ? item.cloud_key : ""
                                    }
                                    alt="product image"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                      {/* <!-- End Gallery --> */}
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                      <div class="detail-info pr-30 pl-30">
                        <h2 class="title-detail">
                          {ProductsVal.item_group_name}
                        </h2>
                        {/* <span class="stock-status out-stock"> Ready Stock </span> */}
                        {/* <div class="product-detail-rating">
                                        <div class="product-rate-cover text-end">
                                            <span class="stock-status out-stock"> Ready Stock </span>
                                        </div>
                                    </div> */}
                        <div class="clearfix product-price-cover">
                          <div class="product-price primary-color float-left">
                            <span class="current-price text-brand">
                              {selectedVar
                                ? userInfo
                                  ? formatPrice(
                                      (selectedVar.sell_price *
                                        (100 -
                                          Math.max(
                                            userInfo.user.role.discount,
                                            parseFloat(
                                              userInfo.user.special_discount
                                            )
                                          ))) /
                                        100
                                    )
                                  : formatPrice(selectedVar.sell_price)
                                : formatPrice(0)}
                            </span>
                            <span>
                              {userInfo && (
                                <>
                                  <span className="save-price font-md color3 ml-15">
                                    {userInfo
                                      ? `${Math.max(
                                          userInfo.user.role.discount,
                                          parseFloat(
                                            userInfo.user.special_discount
                                          )
                                        )}% Off`
                                      : "0% Off"}
                                  </span>
                                  <span className="old-price font-md ml-15">
                                    {selectedVar
                                      ? formatPrice(selectedVar.sell_price)
                                      : formatPrice(0)}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <div class="short-desc mb-30">
                          <p class="font-lg">
                            {isExpanded ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: shortDescription,
                                }}
                              />
                            )}
                          </p>
                          {shortDescription && (
                            <button
                              onClick={toggleReadMore}
                              className="rounded mb-2 btn btn-sm position-relative"
                            >
                              {isExpanded
                                ? "Tampilkan sedikit"
                                : "Tampilkan lebih"}
                            </button>
                          )}
                        </div>
                        <div class="attr-detail attr-size mb-30">
                          <strong class="mr-10 mb-auto ">Variant: </strong>
                          <ul class="list-filter size-filter font-small">
                            {ProductsVal.product_skus &&
                              ProductsVal.product_skus.length > 0 &&
                              Variant.map((variant) => {
                                const isDisabled = variant.end_qty <= 0;
                                return (
                                  <li
                                    key={variant.item_id}
                                    className={`${
                                      selectedVar &&
                                      selectedVar.item_id === variant.item_id
                                        ? "active"
                                        : ""
                                    } ${isDisabled ? "disabled" : ""}`}
                                  >
                                    <a
                                      onClick={
                                        !isDisabled
                                          ? () => handleVariantClick(variant)
                                          : null
                                      }
                                      className="d-flex justify-content-between"
                                    >
                                      <img
                                        src={
                                          variant.images.length > 0
                                            ? variant.images[0].cloud_key
                                            : ""
                                        }
                                        alt=""
                                        height="30px"
                                        className="m-auto"
                                      />
                                      <span>
                                        {variant.variation_values[0].value +
                                          (variant.variation_values[1]
                                            ? "- " +
                                              variant.variation_values[1].value
                                            : "")}
                                      </span>
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div class="font-xs">
                          <ul class="mr-50 float-start">
                            {/* <li class="mb-5">Category: <a href="#" rel="tag">Sarimbit</a></li> */}
                            <li class="mb-5">
                              Stock:
                              <span class="in-stock text-brand ml-5">
                                {selectedVar && selectedVar.end_qty > 0
                                  ? selectedVar.end_qty
                                  : 0}{" "}
                                Item
                              </span>
                            </li>
                            {/* <li class="mb-5">SKU: <a >FWM15VKT</a></li> */}
                          </ul>
                        </div>
                        <div className="detail-extralink mt-100 add-cart-lg">
                          <div className="detail-qty border radius">
                            <a
                              className="qty-down"
                              onClick={(e) => {
                                e.preventDefault();
                                handleQuantityChange(-1);
                              }}
                            >
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <input
                              type="text"
                              name="quantity"
                              className="qty-val"
                              value={quantity}
                              onChange={handleQuantityInputChange}
                              min="1"
                            />
                            <a
                              className="qty-up"
                              onClick={(e) => {
                                e.preventDefault();
                                handleQuantityChange(1);
                              }}
                            >
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div>
                          <div className="product-extra-link2">
                            <button
                              type="button"
                              className="button button-add-to-cart"
                              onClick={handleAddToCart}
                            >
                              <i className="fi-rs-shopping-cart"></i>Tambah ke
                              keranjang
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Detail Info --> */}
                    </div>
                  </div>
                ) : (
                  <div className="text-center pt-50 pb-50">
                    <h3>Mohon tunggu, produk akan muncul sebentar lagi...</h3>
                  </div>
                )}

                {/* rating & testimoni */}
                <div class="row mt-100">
                  <div class="col-12">
                    <h2 class="section-title style-1 mb-10">
                      Testimoni & Rating
                    </h2>
                  </div>
                  <div class="d-flex flex-column gap-3">
                    <RatingAverage />
                    <RatingFilter
                      selectedRating={selectedRating}
                      onFilterChange={setSelectedRating}
                    />
                    <RatingList ratingData={ratingData} />
                  </div>
                </div>

                {/* related products */}
                <div class="row mt-100">
                  <div class="col-12">
                    <h2 class="section-title style-1 mb-30">
                      Related products
                    </h2>
                  </div>
                  <div class="col-12">
                    {RelatedVal.length > 0 ? (
                      <ProductsComponent
                        RelatedVal={RelatedVal}
                        categoryName={categoryName}
                      />
                    ) : (
                      <div className="text-center pt-50 pb-50">
                        <h3>
                          Mohon tunggu, produk akan muncul sebentar lagi...
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="detail-extralink add-cart-mobile">
          <div class="detail-qty border radius">
            <a
              onClick={(e) => {
                e.preventDefault();
                handleQuantityChange(-1);
              }}
              class="qty-down"
            >
              <i class="fi-rs-angle-small-down"></i>
            </a>
            <input
              type="text"
              name="quantity"
              class="qty-val"
              value={quantity}
              onChange={handleQuantityInputChange}
              min="1"
            />
            <a
              onClick={(e) => {
                e.preventDefault();
                handleQuantityChange(1);
              }}
              class="qty-up"
            >
              <i class="fi-rs-angle-small-up"></i>
            </a>
          </div>
          <div class="product-extra-link2">
            <button
              type="submit"
              class="button button-add-to-cart"
              onClick={handleAddToCart}
            >
              <i class="fi-rs-shopping-cart"></i>Add to cart
            </button>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Products;

export const ProductsComponent = ({ RelatedVal, categoryName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const maxPagesToShow = window.innerWidth < 768 ? 3 : 5;

  // Logic to calculate the index range for products to display on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = RelatedVal.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Logic to change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Logic to generate an array of page numbers
  const getPageNumbers = () => {
    const pageNumbers = [];
    const half = Math.floor(maxPagesToShow / 2);

    // Calculate start and end page numbers
    let startPage = Math.max(1, currentPage - half);
    let endPage = Math.min(totalPages, currentPage + half);

    if (currentPage <= half) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + half >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
      endPage = totalPages;
    }

    // Add first page and ellipsis if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("..");
      }
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("..");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const totalPages = Math.ceil(RelatedVal.length / productsPerPage);

  return (
    <>
      <div className="row related-products">
        {currentProducts.map((product) => (
          <div
            className="col-lg-3 col-md-4 col-12 col-sm-6"
            key={product.item_group_id}
          >
            <div className="product-cart-wrap hover-up mb-30">
              <div className="product-img-action-wrap">
                <div className="product-img product-img-zoom">
                  <a href={`/product/${product.item_group_id}`} tabIndex="0">
                    <img
                      className="default-img"
                      src={product.thumbnail}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="product-content-wrap">
                <h2>
                  <a href={`/product/${product.item_group_id}`} tabIndex="0">
                    {product.item_name}
                  </a>
                </h2>
                <div className="product-category">
                  <span className="font-small text-muted">
                    <a href="vendor-details-1.html">{categoryName}</a>
                  </span>
                </div>
                <div className="product-price">
                  <span>{formatPrice(product.sell_price)}</span>
                  <span className="old-price">
                    {formatPrice(product.sell_price + 50000)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination */}
      <div className="pagination-area mt-20 mb-20">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <a
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="fi-rs-arrow-small-left"></i>
              </a>
            </li>
            {getPageNumbers().map((page, idx) =>
              page === "..." ? (
                <li className="page-item" key={idx}>
                  <span className="page-link">...</span>
                </li>
              ) : (
                <li
                  key={idx}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </a>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <i className="fi-rs-arrow-small-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
