import axios from "axios";
import { userInfo } from "../pages/Account/AuthProvider";
import { data, param } from "jquery";

// const urlAPI = "http://127.0.0.1:8000";

// const baseURL = "http://127.0.0.1:8000/api";

const urlAPI = "https://www.delfiafashion.com/admin";

const baseURL = "https://www.delfiafashion.com/api";

export const fetchData = async (
  endpoint,
  query = "",
  callback,
  headers = {},
  showAlert
) => {
  try {
    const url = query
      ? `${baseURL}/${endpoint}?search=${query}`
      : `${baseURL}/${endpoint}`;
    const response = await axios.get(url, { headers });
    callback(response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    if (showAlert) {
      console.error("Error fetching data:", error);
      console.error(error.response.data, "Response error");
      if (error.response?.data?.message == "Token is Expired.") {
        showAlert(
          error.response?.data?.message + " Mohon Login Kembali" ||
            "Error fetching data",
          "danger"
        );
      } else {
        showAlert(
          error.response?.data?.message || "Error fetching data",
          "danger"
        );
      }
    }
  }
};

const directFetchData = async (endpoint, params) => {
  try {
    const response = await axios.get(`${baseURL}/${endpoint}`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const fetchImagesProduct = async (urlAPI, productTypeId, productId) => {
  try {
    const response = await axios.get(`${baseURL}/product/${productId}/images`);
    const imageData = response.data;

    if (
      imageData &&
      Array.isArray(imageData.data) &&
      imageData.data.length > 0
    ) {
      return {
        productTypeId,
        imageUrl: imageData.data,
      };
    }
  } catch (error) {
    console.error(
      `Error fetching product images for product ID ${productId}:`,
      error
    );
    return null;
  }
};

// Function to make a POST request
export const postData = async (endpoint, data, headers = {}) => {
  try {
    const response = await axios.post(`${baseURL}/${endpoint}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
// Function to make a Put request (update)
export const updateData = async (endpoint, data, headers = {}) => {
  try {
    const response = await axios.put(`${baseURL}/${endpoint}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
// Function to make a Delete request
export const deleteData = async (endpoint, data, headers = {}) => {
  try {
    const response = await axios.delete(`${baseURL}/${endpoint}`, {
      headers,
      data,
    });
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// Define endpoints for different API requests
const endpoints = {
  products: "jubelio/products",
  productId: "jubelio/products/{id}",
  category: "jubelio/categories",
  categoryActive: "jubelio/categories/active",
  brands: "jubelio/brands",
  productCat: "jubelio/product/by-category",
  productGroup: "jubelio/product/group",
  productGroupId: "jubelio/product/group/{id}",
  setting: "setting",
  getMember: "user/get-all-member",
  productSearch: "jubelio/product/search",
};
export const fetchProductByGroupId = async (id, callback) => {
  const endpoint = endpoints.productGroupId.replace("{id}", id);
  fetchData(endpoint, "", callback);
};
export const fetchProductById = async (id, callback) => {
  const endpoint = endpoints.productId.replace("{id}", id);
  fetchData(endpoint, "", callback);
};
export const fetchProductByCat = async (id, callback) => {
  const endpoint = endpoints.productCat.replace("{id}", id);
  fetchData(endpoint, "", callback);
};

// Generate functions dynamically for each endpoint
const apiFunctions = {
  products: (callback, showAlert) =>
    fetchData(endpoints.products, "", callback, {}, showAlert),
  productId: (id, callback, showAlert) =>
    fetchData(
      endpoints.productId.replace("{id}", id),
      "",
      callback,
      {},
      showAlert
    ),
  category: (callback, showAlert) =>
    fetchData(endpoints.category, "", callback, {}, showAlert),
  categoryActive: (callback, showAlert) =>
    fetchData(endpoints.categoryActive, "", callback, {}, showAlert),
  brands: (callback, showAlert) =>
    fetchData(endpoints.brands, "", callback, {}, showAlert),
  productCat: (id, callback, showAlert) =>
    fetchData(
      endpoints.productCat.replace("{id}", id),
      "",
      callback,
      {},
      showAlert
    ),
  productGroup: (callback, showAlert) =>
    fetchData(endpoints.productGroup, "", callback, {}, showAlert),
  productGroupId: (id, callback, showAlert) =>
    fetchData(
      endpoints.productGroupId.replace("{id}", id),
      "",
      callback,
      {},
      showAlert
    ),
  setting: (callback, showAlert) =>
    fetchData(endpoints.setting, "", callback, {}, showAlert),
  getMember: (callback, showAlert) => {
    const headers = {
      Authorization: "Bearer " + userInfo.access_token,
    };
    fetchData(endpoints.getMember, "", callback, headers, showAlert);
  },
  productSearch: (query, callback, showAlert) =>
    fetchData(endpoints.productSearch, query, callback, {}, showAlert),
};
// const apiFunctions = {};
// for (const key in endpoints) {
//   if (endpoints.hasOwnProperty(key)) {
//     const endpoint = endpoints[key];
//     apiFunctions[key] = (query, callback) => fetchData(endpoint, query, callback);
//   }
// }
// API RajaOngkir
export const fetchProvinces = async () => {
  try {
    const response = await axios.get(`${baseURL}/ekspedisi/province`);
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching provinces:", error);
    return [];
  }
};

export const fetchCities = async (provinceId) => {
  try {
    const response = await axios.get(
      `${baseURL}/ekspedisi/city?province=${provinceId}`
    );
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export const fetchSubdistricts = async (cityId) => {
  try {
    const response = await axios.get(
      `${baseURL}/ekspedisi/subdistrict?city=${cityId}`
    );
    return response.data.rajaongkir.results;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export const fetchCourier = async () => {
  try {
    const response = await axios.get(`${baseURL}/ekspedisi/courier`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching courier:", error);
    return [];
  }
};

// get ongkir ethica
export const getKurirEthica = async () => {
  try {
    const response = await axios.get(`${baseURL}/ethica/get-grouped-ekspedisi`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get ongkir ethica
export const getOngkirEthica = async (data) => {
  try {
    const response = await axios.post(`${baseURL}/ethica/select-courier`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchHistory = async () => {
  try {
    const headers = {
      Authorization: "Bearer " + userInfo.access_token,
    };
    const response = await axios.get(`${baseURL}/order/history`, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching order history:", error);
    return [];
  }
};
export const fetchAllCourier = async () => {
  try {
    const response = await axios.get(`${baseURL}/ekspedisi/getAllCouriers`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching courier:", error);
    return [];
  }
};

//get detail pesanan
export const getDetailPesanan = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/jubelio/sales/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// add review pesanan
export const addReview = async (data) => {
  try {
    const response = await axios.post(`https://delfiafashion.com/api/review`, {
      reviews: data,
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

//get list review
export const getListReview = async () => {
  try {
    const response = await axios.get(`https://delfiafashion.com/api/review`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

//get review by id
export const getReviewById = async (id) => {
  try {
    const response = await axios.get(
      `https://delfiafashion.com/api/review/${id}`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

//update review
export const updateReview = async (id, data) => {
  try {
    const response = await axios.put(
      `https://delfiafashion.com/api/review/${id}`,
      data // Kirim data langsung, bukan dalam objek `reviewData`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error; // Pastikan error dilempar sehingga bisa ditangani di `handleSubmit`
  }
};

// delete review
export const deleteReview = async (id) => {
  try {
    const response = await axios.delete(
      `https://delfiafashion.com/api/review/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// diskon member
export const getDiskonMember = async (discount, user) => {
  const { access_token: token } = userInfo;
  try {
    const response = await axios.put(
      `${baseURL}/user/discount-update/${user}`,
      { special_discount: discount },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // Throw error so it can be caught in the component
  }
};

// get rating & review
export const getRating = async (productId, rating) => {
  try {
    const response = await axios.get(`${baseURL}/review/product/${productId}`, {
      params: rating ? { rating } : {}, // Menambahkan query params rating jika ada
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// get all products ethica
export const getProductsEthica = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/ethica/get-barang?offset=0&is_ada_stok=T`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get by artikel
export const getProductsByArtikel = async (nama_artikel) => {
  try {
    const response = await axios.get(
      `${baseURL}/ethica/get-barang?offset=0&is_ada_stok=T&artikel=${nama_artikel}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// add ethica product to cart
export const addEthicaProductToCart = async (data) => {
  const { access_token: token } = userInfo;
  try {
    const response = await axios.post(`${baseURL}/ethica/add-to-cart`, data, {
      headers: {
        Authorization: `Bearer ${token}`, // Mengirimkan token dalam header
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get ethica product from cart
export const getEthicaProductFromCart = async () => {
  try {
    const response = await axios.get(`${baseURL}/ethica/get-cart`, {
      params: {
        user_id: userInfo.user.id,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// delete cart
export const deleteCart = async (seq) => {
  try {
    const response = await axios.get(
      `${baseURL}/ethica/delete-from-cart/${seq}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// checkout ethica
export const checkoutEthica = async (data) => {
  const { access_token: token } = userInfo;
  try {
    const response = await axios.post(`${baseURL}/ethica/create-order`, data, {
      headers: {
        Authorization: `Bearer ${token}`, // Mengirimkan token dalam header
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get ethica order
export const getEthicaOrder = async (order_id) => {
  try {
    const response = await axios.get(`${baseURL}/ethica/order-detail`, {
      params: {
        seq_pesanan: order_id,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get approval order
export const getApprovalOrder = async (approved) => {
  try {
    const response = await axios.get(
      `${baseURL}/customer/order/getApprovedOrders`,
      {
        params: {
          approved: approved,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// approval order
export const approvalOrder = async (order_id) => {
  const { access_token: Token } = userInfo;
  try {
    const response = await axios.post(
      `${baseURL}/customer/order/approveOrder/${order_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// get all blog
export const getBlog = async () => {
  try {
    const response = await axios.get(`${baseURL}/blog`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// post new blog
export const addBlog = async (data) => {
  const { access_token: Token } = userInfo;
  try {
    const response = await axios.post(`${baseURL}/blog`, data, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const editBlog = async (id, data) => {
  const { access_token: Token } = userInfo;
  try {
    const response = await axios.post(
      `${baseURL}/blog/update/${id}?_method=PUT`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//get blog by id
export const getBlogById = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/blog/${id}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBlog = async (id) => {
  const { access_token: Token } = userInfo;
  try {
    const response = await axios.delete(`${baseURL}/blog/${id}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// post about
export const addAbout = async (data) => {
  const { access_token: Token } = userInfo;
  try {
    const response = await axios.post(`${baseURL}/about`, data, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// get about
export const getAbout = async () => {
  try {
    const response = await axios.get(`${baseURL}/about`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// Export API functions and baseURL
export { apiFunctions, urlAPI, directFetchData, fetchImagesProduct };
