import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { FaWhatsappSquare } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { apiFunctions, fetchData } from '../../../services/api.services';

export default function FloatingActionButtons() {
  const [footerSet, setFooterSet] = useState(null);

  useEffect(() => {
    fetchData('footer/menu', '', (data) => {
      setFooterSet(data.data.settings);
    });
  }, []); // Empty dependency array ensures this runs only once

  return (
      <>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
        {footerSet &&
            <a href={`https://api.whatsapp.com/send/?phone=${parseInt(footerSet.whatsapp)}`} className="float" target="_blank">
              <i className="fa fa-whatsapp my-float"></i>
            </a>
        }
      </>
  );
}
