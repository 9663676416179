import { useEffect, useState } from "react";
import QuillEditor from "../../components/commons/quilleditor";
import { addBlog, editBlog, urlAPI } from "../../services/api.services";
import axios from "axios";
import { userInfo } from "../Account/AuthProvider";

export const TambahArtikel = ({ onCancel, showAlert, refreshArtikel }) => {
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    description: "",
  });

  const handleFormChange = (e) => {
    const { name, files, type } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "file" ? files[0] : e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    if (formData.image) {
      data.append("image", formData.image); // Tambahkan file image
    }

    try {
      const response = await addBlog(formData);
      showAlert("Artikel Berhasil ditambah.", "success");
      onCancel(); // Tutup form
      refreshArtikel(); // Perbarui daftar artikel
    } catch (error) {
      showAlert("Artikel Gagal ditambah", "danger");
    }
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="mb-0">Tambah Artikel</h3>
      </div>
      <div className="card-body">
        <form method="post" onSubmit={handleSubmit}>
          <div class="row pt-3">
            <div class="form-group col-md-12">
              <label>
                Judul Artikel <span class="required">*</span>
              </label>
              <input
                required
                class="form-control h-75"
                type="text"
                name="title"
                value={formData.title}
                onChange={handleFormChange}
              />
            </div>

            <div class="form-group col-md-12">
              <label>
                Deskripsi Artikel <span class="required">*</span>
              </label>
              <QuillEditor
                value={formData.description}
                onChange={(value) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: value,
                  }))
                }
              />
            </div>
            <div class="form-group col-md-12">
              <label>
                Gambar Artikel <span class="required">*</span>
              </label>
              <br />
              {/* {formData.image && <img src={urlAPI + formData.image} alt="" />} */}
              {formData.image && (
                <img
                  src={URL.createObjectURL(formData.image)}
                  alt=""
                  style={{ maxWidth: "100px", marginBottom: "10px" }}
                />
              )}
              <input
                class="form-control h-75 border-0"
                type="file"
                name="image"
                accept="image/jpeg, image/png, image/jpg, image/gif, image/svg+xml"
                onChange={handleFormChange}
              />
            </div>
            <div class="col-md-12 pt-2">
              <div className="d-flex align-items-center gap-4">
                <button
                  class="btn btn-fill-out submit font-weight-bold"
                  onClick={onCancel}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  class="btn btn-fill-out submit font-weight-bold"
                  value="Submit"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const EditArtikel = ({
  article,
  onCancel,
  showAlert,
  refreshArtikel,
}) => {
  const [title, setTitle] = useState(article?.title || "");
  const [description, setDescription] = useState(article?.description || "");
  const [existingImage, setExistingImage] = useState(article?.image || "");
  const [changeImage, setChangeImage] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setDescription(article.description);
      setExistingImage(article.image);
    }
  }, [article]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setChangeImage(e.target.result); // Update the image preview
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      showAlert("Judul tidak boleh kosong", "danger");
      return;
    }

    if (!description.trim()) {
      showAlert("Deskripsi tidak boleh kosong", "danger");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (image) {
      formData.append("image", image); // Include filename
    }

    try {
      const response = await editBlog(article.id, formData);

      console.log(response);

      showAlert("Artikel Berhasil diperbarui.", "success");
      onCancel(); // Tutup form
      refreshArtikel(); // Perbarui daftar artikel
    } catch (error) {
      // Log complete error for debugging
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      showAlert("Artikel Gagal diperbarui", "danger");
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="mb-0">Edit Artikel</h3>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row pt-3">
            <div className="form-group col-md-12">
              <label>
                Judul Artikel <span className="required">*</span>
              </label>
              <input
                required
                className="form-control h-75"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="form-group col-md-12">
              <label>
                Deskripsi Artikel <span className="required">*</span>
              </label>
              <QuillEditor value={description} onChange={setDescription} />
            </div>

            <div className="form-group col-md-12">
              <label>Gambar Artikel (Opsional)</label>
              <br />
              {existingImage && (
                <img
                  src={image ? changeImage : urlAPI + existingImage}
                  alt={title}
                  style={{
                    maxWidth: "100px",
                    marginBottom: "10px",
                  }}
                />
              )}
              <input
                className="form-control h-75 border-0"
                name="image"
                type="file"
                accept="image/jpeg, image/png, image/jpg, image/gif, image/svg+xml"
                onChange={handleImageChange}
              />
            </div>

            <div className="col-md-12 pt-2">
              <div className="d-flex align-items-center gap-4">
                <button
                  type="button"
                  className="btn btn-fill-out submit font-weight-bold"
                  onClick={onCancel}
                >
                  Batal
                </button>
                <button
                  type="submit"
                  name="submit"
                  value="Submit"
                  className="btn btn-fill-out submit font-weight-bold"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
